if(!window.vars){
    window.vars = {};
}

if(!window.utils){
    window.utils = {};
}



(function(window){
    'use strict';

    var QsUtil = {
        //将带?key1=value1&key2=value2类型的字符串转换成对象
        parseQueryStringToObj : function(url){
            var obj = [];
            var keyvalue = [];
            var key = "",
                value = "";

            if(url == ''){
              return obj;
            }
            var paraString = url.substring(url.indexOf("?") + 1, url.length).split("&");
            for (var i in paraString) {
                keyvalue = paraString[i].split("=");
                key = keyvalue[0];
                value = keyvalue[1];
                obj[key] = value;
            }
            return obj;
        },
        //将对象转换成key1=value1&key2=value2形式的字符串
        parseObjToQueryString : function (arr,urlencode){
            if (urlencode==undefined){
                urlencode=true;
            }
            var para= [];
            for(var o in arr){
                if(o === undefined || o === '' || o === null){
                    continue;
                }
                if (urlencode) {
                    para.push(encodeURIComponent(o) + "=" + encodeURIComponent(arr[o]));
                }else{
                    para.push(o + "=" + arr[o]);
                }
            }
            return para.join("&");
        },

        //合并对象
        mergeObj : function(obj1, obj2){
            for(var o in obj2){
                obj1[o] = obj2[o];
            }
            return obj1;
        }
    };

    window.QsUtil = QsUtil;
}(window))

if(!window.functions){
    window.functions = {};
}


;(function($){
    //tab-item用active类名来表示高亮
    //tab-item-content使用current表示显示
	var Tab = function(tab){
		var _this = this;
		// 保存单个tab组件
		this.tab = tab;
		// 默认配置参数
		this.config = {
			"triggerType":"mouseover", // 用来定义鼠标的触发类型，是click还是mouseover
			"effect":"default", // 用来定义内容切换效果，是直接切换还是淡入淡出效果
			"invoke":1, // 默认展示第几个tab
			"auto":false // 定义tab是否自动切换，及自动切换时间间隔
		}
		// 如果配置参数存在，就扩展掉默认配置参数
		if(this.getConfig()){
			$.extend(this.config,this.getConfig());
		}
		
		// 保存tab标签列表、对应的内容列表
		this.tabItems = this.tab.find(".tab-item");
		this.contentItems = this.tab.find(".tab-item-content");

		// 保存配置参数
		var config = this.config;

		if(config.triggerType === "click"){
			this.tabItems.bind(config.triggerType,function(){
				_this.invoke($(this));
			})
		}else if(config.triggerType != "click"){
			this.tabItems.bind("mouseover",function(){
				_this.invoke($(this));
			})
		}

		// 自动切换功能，如果配置了时间，我们就根据时间间隔进行自动切换
		if(config.auto){
			// 定义一个全局的定时器
			this.timer = null;
			// 计数器
			this.loop = 0;

			this.autoPlay();

			this.tab.hover(function(){
				window.clearInterval(_this.timer);
			},function(){
				_this.autoPlay();
			})
		}

		// 设置默认显示第几个tab
		if(config.invoke > 1){
			this.invoke(this.tabItems.eq(config.invoke-1));
		}
	};

	Tab.prototype = {
		// 自动间隔时间切换
		autoPlay: function(){
			var _this = this,
				tabItems = this.tabItems, // 临时保存tab列表
				tabLength = tabItems.size(), // tab的个数
				config = this.config;
			this.timer = window.setInterval(function(){
				_this.loop ++;
				if(_this.loop >= tabLength){
					_this.loop = 0;
				};
				tabItems.eq(_this.loop).trigger(config.triggerType);
			},config.auto);
		},

		// 事件驱动函数
		invoke: function(currentTab){
			var _this = this;
            var index = currentTab.index();
			// tab选中状态
			currentTab.addClass('active').siblings().removeClass('active');
			// 切换对应的内容区域
			var effect = this.config.effect;
			var conItems = this.contentItems;
			if(effect === "default" || effect != "fade"){
				conItems.eq(index).addClass('current').siblings().removeClass('current');
			}else if(effect === "fade"){
				conItems.eq(index).fadeIn().siblings().fadeOut();
			};
			// 注意：如果参数配置了自动切换时间，要把当前的loop的值设置成当前tab的index
			if(this.config.auto){
				this.loop = index;
			}
		},

		// 获取配置参数
		getConfig: function(){
			// 拿一下tab elem节点上的data-config
			var config = this.tab.attr("data-config");
            // 确保有配置参数
			if(config&&config!=""){
				return $.parseJSON(config);
			}else{
				return null;
			}
		}
	};
	var MenuIcon = function (node,options){
		this.node = $(node);
		this.options = options;
		var _this = this;
		this._li = this.node.find("li");
		this.init();
		this.node.find('li .item').click(function(){
			_this.nodeClick($(this).parent('li'));
		});
	}
	
	MenuIcon.prototype.init = function(){
		this.node.find('.item-content').hide();
		this.node.find('.item-content').each(function(){
			if($(this).data('show') == 1) {
				$(this).show();
			}
		});
		this.node.find('.item .iconfont:last-child').addClass(this.options.hide);
	}
	
	MenuIcon.prototype.nodeClick = function(_this){
		var itemContent = _this.find(".item-content"),
			toggles = itemContent.siblings(".item").find(".iconfont:last");
	
		this.node.find('.item').find(".iconfont:last").removeClass(this.options.show).addClass(this.options.hide);
		if(itemContent.css("display") == "none"){
			this.node.find('.item-content').hide(300);
			toggles.removeClass(this.options.hide).addClass(this.options.show);
			itemContent.slideDown();
		}else{
			itemContent.slideUp();
			toggles.removeClass(this.options.show).addClass(this.options.hide);
		}
	}
	//图片验证与发送手机验证码
	var Verification = function (form,settings) {  
		var _this = this;
		this.wait = 60;
		this.form = form;
		this.config = settings;
		// settings必须包含img和url
		if(!settings.img || !settings.url){
			console.error('请检查配置参数是否正确。');
		}
		form.find('.validate-img').bind('click',function(){
			_this.loadImg(form,_this);
		})
		form.find('.send_code').bind('click',function(e){
			console.log(_this.sendCode)
			_this.sendCode(form,e,_this);
		})
	}
	Verification.prototype = {
		time: function (o,self) {
			var _this=this;
			if (self.wait == 0) {         
				o.html("发送验证码");
				self.wait = 60;
				o.attr('disabled', false);
			} else {
				o.html(self.wait + " s");
				self.wait--;
				setTimeout(function() {
					_this.time(o,self)
				},
				1000)
			}
		},
		loadImg: function (form,self) {  
			var img = form.find('.validate-img > img').attr('src', self.config.img)
				.load(function() {
					if (!this.complete || typeof this.naturalWidth == "undefined" || this.naturalWidth == 0) {
						alert('验证码图片加载出错，请刷新');
					} else {
						form.find('.validate-img').html('<i class="icon-refresh"></i>').prepend(img);
					}
				});
			return false;
		},
		sendCode: function (form,e,self) {
			var _this=this;
			var code = form.find('.input-default input[name="verify"]').val();//验证码
            	e.preventDefault();
                if(self.wait != 60){
                    return;
                }
                var btn_o = form.find('a.send_code');
				var mobile = form.find('input[name=email]').val();
                $.ajax({
                    url: self.config.url + "?email=" + mobile + '&code=' + code,
                    beforeSend: function() {
                        btn_o.html('加载中');
                        btn_o.attr('disabled', true);
                        return true;
                    },
                    success: function(data) {
                        //成功状态，下面的是服务器返回的，数据库操作的状态
                        if(data.status == 1){
                            _this.time(btn_o,self);
                        }
                        else{
                            btn_o.html('发送验证码 <br> Send Verification code');
                            btn_o.attr('disabled', false);
							self.loadImg(form,self);
                        }
                        $.msgBox({
                            title: "提示",
                            content: data.info,
                            type: "info",
                        });
                    },
                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                    	$.msgBox({
                            title: "错误",
                            content: $(XMLHttpRequest.responseText).find('h1').text(),
                            type: "error",
                        });
                        btn_o.html('获取验证码');
                        btn_o.attr('disabled', false);
						self.loadImg(form,self);                    
                    },
                });
		}
	}

	// 注册成jq方法
	$.fn.extend({
		tab: function(){
			this.each(function(){
				new Tab($(this));
			});
			return this;
		},
		verification: function (obj) {  
			this.each(function(){
				new Verification($(this),obj);
			});
			return this;
		}
	})

	window.Tab = Tab;
	window.Verification = Verification;
	window.MenuIcon = MenuIcon;
})(jQuery);
(function ($) {
    $.fn.countup = function (params) {
      // make sure dependency is present
      if (typeof CountUp !== 'function') {
        console.error('countUp.js is a required dependency of countUp-jquery.js.');
        return;
      }
  
      var defaults = {
        startVal: 0,
        decimalPlaces: 0,
        duration: 4,
      };
      if (typeof params === 'number') {
          defaults.endVal = params;
        }
      else if (typeof params === 'object') {
        $.extend(defaults, params);
      }
      this.each(function (i, elem) {
          let newEndVal = 0;
          newEndVal = defaults.endVal ? defaults.endVal : $(elem).data('number');
          console.log(defaults);
          var countUp = new CountUp(elem, newEndVal, defaults);
        countUp.start();
      });
  
      return this;
    };
  
  }(jQuery));
class AjaxPage{
    constructor(el, option = {}){
        this.$el = $(el);
        this.$pageEl = this.$el.find('a');
        this.initPage = this.$el.find('.page-number.current').text().trim();
        this.totalPage = this.$el.data('total-page');
        this.page = this.initPage;
        this.url = '';
        this.lockStatus = false;

        this.$ajaxPageBox = this.$el.prev();
        this.domIndex = this.$el.index('.ajax-page');

        this.$parent = this.$el.parent();

        let defOpt = {
            activePageClass: 'current',
        };

        this.option = Object.assign({}, defOpt, option);

        this.init();
    }

    init(){
        let that = this;

        this.initDom();
    
        this.$el.find('input[name=page]').on('keyup', function (e) {
            if(e.keyCode === 13){
                that.$el.find('a.confirm').trigger('click');
            }
        });

        this.$pageEl.on('click', function (e) {
            e.preventDefault();
            if(that.isLock()){
                return false;
            }
            that.lock();

            let $this = $(this);

            let newPage = that.getPage($this);
            if(!newPage){
                return false;
            }

            that.$parent.addClass('ajax-page-loading__active');
            $this.siblings().removeClass(that.option.activePageClass);
            $this.addClass(that.option.activePageClass);

            that.url = that.getUrl(newPage);

            $("html,body").animate({
                scrollTop: that.$parent.offset().top - 150
            }, 300);

            that.getData().then((res)=> {

                let $newDomPage = $(res).find('.ajax-page').eq(that.domIndex);

                that.$ajaxPageBox.empty()
                    .html($newDomPage.prev().children());
                that.$parent.removeClass('ajax-page-loading__active');

                let newId = `ajax-page-${parseInt(new Date().getTime() * Math.random() * Math.random())}`;
                $newDomPage.attr('id', newId);
                that.$el.replaceWith($newDomPage);
                new AjaxPage(`#${newId}`, that.option);

                that.unLock();
            });
        });

    }

    updatePage(){

    }

    initDom(){
        if(this.$parent.hasClass('ajax-page-loading')){
            return false;
        }
        this.$parent.addClass('ajax-page-loading')
            .append(`
            <i class="icon ajax-page-loading-icon"></i>
            <div class="loading-mask"></div>
        `);
    }

    isLock(){
        return this.lockStatus;
    }

    lock(){
        this.lockStatus = true;
        return true;
    }

    unLock(){
        this.lockStatus = false;
    }

    getPage($el){
        let opera = $el.data('opera');
        let page = this.page;
        switch (opera) {
            case 'prev':
                --page;
                break;
            case 'pages':
                page = $el.text().trim();
                break;
            case 'next':
                ++page;
                break;
            case 'custom':
                page = $el.prevAll('input[name=page]').val();
                break;
            default:
                page = 1;
        }
        page = Math.max(1, page);
        page = Math.min(this.totalPage, page);

        this.page = page;
        return page;
    }

    getData(){
        return $.ajax({
            url: this.url,
        }).success((res)=>{
            return res;
        });
    }

    getUrl(page){
        var search = window.location.search;
        var url=window.location.href;
        if (search.indexOf('?')===-1){
            search='?page='+page;
            url+=search;
        }else if (search.indexOf('page=')===-1){
            url+='&page='+page;
        }else{
            url=url.replace(/page=\d+/,'page='+page);
        }
        return url;
    };
}

// 该插件需要中国地图数据json，还有引入echarts.js
// __PUBLIC__/static/libs/china.json

class EchartsChina {
    constructor(options) {
        this.myChart = null;
        this.defaultOptions = {
            chinaJsonUrl: '',//中国地图数据json格式
            geoData: [],//地图
            mapData: [],//渲染在地图上的数据
            mapContainer:  '#map-container', //echarts的container
            mapBgColor: '#d6d7d6', //地图背景
            mapBgEmphasis: '#00aa80', //地图背景高亮
            symbol: 'circle', //标记的图形，'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
            symbolSize: 10,
            symbolBgColor: '#ffffff', //标记的背景颜色
            symbolBgEmphasis: '#f7e496',//标记的背景高亮
            customHtml: function () {},//弹窗的自定义html
            tooltipPosition: ['90%','0%'] //弹窗的位置
        };
        this.options = Object.assign(this.defaultOptions, options);
    }
    setOption() {
        let that = this;
        let option = {
            tooltip: {
                trigger: 'item',
                position: this.options.tooltipPosition,
                // alwaysShowContent: true,
                triggerOn: 'none',
                extraCssText: 'white-space: normal;box-shadow: 0px 2px 20px 0px rgb(9 9 9 / 24%);overflow: hidden;border-radius:6px;padding: 0px;',
                formatter: function (params) {
                    return that.options.customHtml(params);
                }
            },
            geo: {
                map: 'china',
                show: true,
                roam: false, //拖动和缩放
                label: {
                    normal: {
                        show: false
                    },
                    emphasis: {
                        show: false,
                    }
                },
                regions: this.getCityList(this.options.mapData),
                itemStyle: {
                    normal: {
                        areaColor: this.options.mapBgColor,
                        borderColor: '#fff',//线
                        shadowColor: 'none',//外发光
                        shadowBlur: 20
                    },
                    emphasis: {
                        areaColor: this.options.mapBgEmphasis,//悬浮区背景
                    }
                }
            },
            series: [
                {

                    symbolSize: 5,
                    itemStyle: {
                        normal: {
                            color: '#fff'
                        }
                    },
                    name: 'light',
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    data: this.convertData(this.options.mapData),

                },
                {
                    type: 'map',
                    map: 'china',
                    geoIndex: 0,
                    aspectScale: 0.75, //长宽比
                    showLegendSymbol: false, // 存在legend时显示
                    label: {
                        normal: {
                            show: false
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    roam: true,
                    animation: false,
                    data: this.options.mapData
                },
                {
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    symbol: this.options.symbol,
                    symbolSize: this.options.symbolSize,
                    label: {
                        normal: {
                            show: false,
                            textStyle: {
                                color: '#fff',
                                fontSize: 9,
                            },
                            formatter(value) {
                                return value.data.value[2]
                            }
                        }
                    },
                    itemStyle: {
                        color: this.options.symbolBgColor, 
                    },
                    emphasis: {
                        itemStyle: {
                            color: this.options.symbolBgEmphasis
                        }
                    },
                    data: this.convertData(this.options.mapData),
                    showEffectOn: 'render',
                    rippleEffect: {
                        brushType: 'stroke'
                    },
                    hoverAnimation: true,
                    zlevel: 1
                },

            ]
        };
        this.myChart.setOption(option);
    }
    getCityList(data) {
        let selectedCityList = [];
        data.forEach(item => {
            selectedCityList.push({name: item.name,selected: true});
        })
        return selectedCityList;
    }
    convertData(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
            var geoCoord = this.options.geoData[data[i].name];
            if (geoCoord) {
                res.push({
                    name: data[i].name,
                    value: geoCoord.concat(data[i].value)
                });
            }
        }
        return res;
    }
    initEcharts() {
        this.myChart = echarts.init(document.querySelector(this.options.mapContainer));
    }
    getChinaJson(cb) {
        if(this.options.chinaJsonUrl) {
            $.getJSON(this.options.chinaJsonUrl, function (mapData) {
                console.log(mapData);
                if(cb && typeof cb === 'function') {
                    cb(mapData)
                }
            })
        }else{
            throw '缺少中国json数据地址';
        }
    }
    init() {
        this.initEcharts()
        this.myChart.showLoading();
        this.getChinaJson((data) => {
            echarts.registerMap('china', data);
            this.myChart.hideLoading();
            this.setOption();
        })
        return this.myChart
    }
}
let loadImg = function (url) {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = () => resolve(image);
        image.onerror = reject;
        image.src = url;
    });
}
let loadIndexBanner = function (className) {
    let loadDom = [];
    $(className).each(function (i) {
        let newClassName = className + '-load-img-' + i;
        $(this).addClass(newClassName.slice(1));
        loadDom.push(newClassName);
    })
    loadDom.forEach((item,i) => {
        loadImg($(item).data('big-img')).then(res => {
            $(item).css({'background-image': 'url(' + $(item).data('big-img') + ')'})
        })
    })
}
function addSpanSearch(keyword, searchClass, spanClass) {
    console.log('调用成功');
     keyword = String(keyword).trim();
     if (!keyword) {
        return false;
     }
     $(searchClass).each(function () {
        var $dom = $(this);
        let regString = /(\$|\^|\*|\\|\+|\?|\.)/gi;
        let oldKeyWord = keyword;
        keyword = keyword.replace(regString, '\\'+ '$1');
        let reg = new RegExp('(' + keyword + ')', 'g');
        if ($dom.text().indexOf(oldKeyWord) !== -1) {
           $dom.children().each(function () {
              $(this)
                 .find('[data-highlight]')
                 .each(function () {
                     var newHtml = $(this)
                        .text()
                        .replace(reg, '<span class="' + spanClass + '">$1</span>');
                     $(this).html(newHtml);
                 });
           });
        }
     });
  }
class open {
    constructor(el) {
        this.el = el;
        this.$el = $(el);
        this.content = "";
        this.id = 1;
        this.init();
    }

    init() {
        let me = this;
        me.$el.find("a").click(function() {
            me.getID($(this).parent());
            me.$el.find(".answer").hide();
            $(this).parent().siblings().removeClass("active");
            $(this).parent().parent().parent().siblings().find(".question-ask").removeClass("active");
            me.$el.css({
                "border-bottom": "none"
            })
            $(this).parent().toggleClass("active");
            if ($(this).parent().hasClass("active") && $(this).siblings('.answer').length == 0) {
                me.request($(this).parent());
            } else if ($(this).parent().hasClass("active") && $(this).siblings('.answer').length != 0) {
                $(this).siblings('.answer').show();
                me.borderShow($(this).parent());
            }
            else {
                console.log("移除");
                $(this).parent().find(".answer").hide();
                $(this).css({
                    "border-bottom": "none"
                })

            }
        });
    }

    getID($this) {
        this.id = $this.data("id");
    }

    addContent($el) {
        let contentDiv = `<div class="answer article-content">${this.content}</div>`;
        $el.append(contentDiv);
        $el.css({
            "border-bottom": "1px solid #eee"
        })
        $(".box-shadow").remove();
    }

    borderShow($el) {
        $el.css({
            "border-bottom": "1px solid #eee"
        })
    }

    request($el) {
        let me = this;
        $.ajax({
            data: "id=" + this.id,
            beforeSend: function() {
                $el.find("a").append("<span class='box-shadow'><span class='loader-01'></span></span>");
            },
            success: (data) => {
                me.content = data;
                me.addContent($el);
            }
        });
    }
}


//编辑器设置
var editorInit={
    toolbars: [
        [
            'undo', //撤销
            'redo', //重做

            'bold', //加粗

            'italic', //斜体

            'underline', //下划线

            'paragraph', //段落格式

            'simpleupload', //单图上传

            'insertimage', //多图上传

            'justifyleft', //居左对齐

            'justifyright', //居右对齐

            'justifycenter', //居中对齐

            'justifyjustify', //两端对齐

            'insertorderedlist', //有序列表

            'insertunorderedlist', //无序列表

            'fullscreen', //全屏

        ]

    ],

    topOffset:0,

    pasteplain: true,//是否默认为纯文本粘贴。false为不使用纯文本粘贴，true为使用纯文本粘贴

    retainOnlyLabelPasted: true  //粘贴只保留标签，去除标签所有属性

}

var swiperDefaultConf = {
    autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
    },
	pagination :{
		clickable :true,
	},
    loop: true
};

function initSwiper(container, confObj = {}) {
    var $container = $(container),
        conf = {},
	    pagination = {};

	confObj.pagination = confObj.pagination || {};
	Object.assign(pagination, swiperDefaultConf.pagination, confObj.pagination);
	Object.assign(conf, swiperDefaultConf, confObj);
	conf.pagination = pagination;

    if (!$container.length) {
        return false;
    }

    var mySwiper = new Swiper(container, conf);
    $(container).mouseleave(function () {
        mySwiper.autoplay.start();
    });

    return mySwiper;
}
var getQueryVariable = function(variable){
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
    }
    return(false);
}

$(function () {
    $('.tab-top-menu a').on('click', function () {
        $(this).parents('.tab-top-menu').find('a').removeClass('active');
        $(this).addClass('active');
    });

    $('.img-gallery').each((index, item)=>{
        let $el = $(item);
        let childTagName = '';
        try{
            childTagName = $el.children().first().get(0).tagName;
        }catch(e){
        }
        if(childTagName){
            $el.magnificPopup({
                delegate: childTagName,
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: false,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                image: {
                    verticalFit: true,
                    titleSrc: function(item) {
                    }
                },
                gallery: {
                    enabled: true
                },
                zoom: {
                    enabled: true,
                    duration: 300, // don't foget to change the duration also in CSS
                    opener: function(element) {
                        return element.find('img');
                    }
                }

            })
        }
    });
});


$(function () {
    // var autoFooterMargin = function () {
    //     var bodyWidth = document.body.clientWidth;
    //     // var footerWidth = document.querySelector('.footer-bottom-c').clientWidth;
    //     var margin = (bodyWidth - footerWidth) / 2;
    //     $('.footer-bottom-c').css({'margin': '0 ' + margin + 'px'});
    // }
    // autoFooterMargin();
    // window.onresize = autoFooterMargin;

    var togglePage = function (e) {
        if(e.keyCode === 13){
            $('.pagination-box').not('.ajax-page').find('.confirm').trigger('click');
        }
    };
    $('.pagination-box input[name=page]').on('focus', function () {
        $(window).on('keyup', togglePage);
    }).on('blur', function () {
        $(window).off('keyup', togglePage);
    });

    var getUrl = function(page){
        var search=window.location.search;
        var url=window.location.href;
        if (search.indexOf('?')===-1){
            search='?page='+page;
            url+=search;
        }else if (search.indexOf('page=')===-1){
            url+='&page='+page;
        }else{
            url=url.replace(/page=\d+/,'page='+page);
        }
        return url;
    };

    $('.pagination-box').not('.ajax-page').find('.confirm').on('click',function () {
        var page=$(this).prevAll('input[name=page]').val();
        var totalPage = $(this).parents('.pagination-box').data('total-page');
        page = parseInt(page);
        if (!page){
            return;
        }
        page = Math.max(0, page);
        page = Math.min(totalPage, page);
        var url = getUrl(page);
        $(this).attr('href',url);
        window.location.href=url;
    });


    $('.ajax-page').each(function () {
        new AjaxPage(this);
    });

    $('.img-gallery').each((index, item)=>{
        let $el = $(item);
        let childTagName = '';
        try{
            childTagName = $el.children().first().get(0).tagName;
        }catch(e){
        }
        if(childTagName){
            $el.magnificPopup({
                delegate: childTagName,
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: false,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                image: {
                    verticalFit: true,
                    titleSrc: function(item) {
                    return $(item.el[0]).data('title');
                    }
                },
                gallery: {
                    enabled: true
                },
                zoom: {
                    enabled: true,
                    duration: 300, // don't foget to change the duration also in CSS
                    opener: function(element) {
                    console.log('element', element);
                    return element.find('img');
                    }
                }
                
            })
        }
    });


});
